<template>
  <Lock helpless>
    <article class="uk-height-viewport uk-flex uk-flex-column uk-flex-middle">
      <SiteHeader class="uk-margin-top"></SiteHeader>
      <section class="uk-width-expand uk-text-left uk-flex-none uk-padding" style="max-width: 600px;">
        <h2 class="uk-text-center">website terms of use</h2>
        <dl class="uk-description-list">
          <dt>1. Application Of Terms</dt>
          <dd>
1.1. These Terms apply to your use of the Website. By accessing and using the Website:
            <ol type="a">
              <li>you agree to these Terms; and</li>
              <li>
where your access and use is on behalf of another person (e.g. a company), you confirm
that you are authorised to, and do in fact, agree to these Terms on that person’s behalf
and that, by agreeing to these Terms on that person’s behalf, that person is bound by
these Terms.
              </li>
            </ol>
1.2. If you do not agree to these Terms, you are not authorised to access and use the Website, and
you must immediately stop doing so.
          </dd>
          <dt>2. Changes</dt>
          <dd>
            <p>
2.1. We may change these Terms at any time by updating them on the Website. Unless stated
otherwise, any change takes effect immediately. You are responsible for ensuring you are
familiar with the latest Terms. By continuing to access and use the Website, you agree to be
bound by the changed Terms.
            </p>
            <p>
2.2. We may change, suspend, discontinue, or restrict access to, the Website without notice or
liability.
            </p>
            <p>2.3. <strong>These Terms were last updated on 2019-11-07</strong>.</p>
          </dd>
          <dt>3. Definitions</dt>
          <dd>
            In these Terms:
            <ul class="uk-list">
              <li><em>including</em> and similar words do not imply any limit</li>
              <li>
<em>Loss</em> includes loss of profits, savings, revenue or data, and any other claim, damage,
loss, liability and cost, including legal costs on a solicitor and own client basis
              </li>
              <li>
<em>personal information</em> means information about an identifiable, living person
              </li>
              <li><em>Terms</em> means these terms and conditions titled Website Terms of Use</li>
              <li>
<em>Underlying System</em> means any network, system, software, data or material that underlies or
is connected to the Website
              </li>
              <li>
<em>User ID</em> means a unique name and/or password allocated to you to allow you to access
certain parts of the Website
              </li>
              <li><em>We, us or our</em> means Squirrel Technologies Limited</li>
              <li><em>Website</em> means squirreltechnologies.nz or acornconnect.com</li>
              <li>
<em>You</em> means you or, if clause 1.1b applies, both you and the other person on whose behalf
you are acting.
              </li>
            </ul>
          </dd>
          <dt>4. Your Obligations</dt>
          <dd>
            <p>
4.1. You must provide true, current and complete information in your dealings with us (including
when setting up an account), and must promptly update that information as required so that the
information remains true, current and complete.
            </p>
            <p>
4.2. If you are given a User ID, you must keep your User ID secure and:
            </p>
            <ol type="a">
              <li>
not permit any other person to use your User ID, including not disclosing or providing it to
any other person; and
              </li>
              <li>
immediately notify us if you become aware of any disclosure or unauthorised use of your
User ID, by sending an email to security@squirreltechnologies.nz.
              </li>
            </ol>
            <p>4.3. You must:</p>
            <ol type="a">
              <li>
not act in a way, or use or introduce anything (including any virus, worm, Trojan horse,
timebomb, keystroke logger, spyware or other similar feature) that in any way
compromises, or may compromise, the Website or any Underlying System, or otherwise
attempt to damage or interfere with the Website or any Underlying System; and
              </li>
              <li>
unless with our agreement, access the Website via standard web browsers only and not
by any other method. Other methods include scraping, deep-linking, harvesting, data
mining, use of a robot or spider, automation, or any similar data gathering, extraction or
monitoring method.
              </li>
            </ol>
            <p>
4.4. You must obtain our written permission to establish a link to our Website. If you wish to do
so, email your request to webmaster@squirreltechnologies.nz.
            </p>
            <p>
4.5. You indemnify us against all Loss we suffer or incur as a direct or indirect result of your
failure to comply with these Terms, including any failure of a person who accesses and uses our
Website by using your User ID.
            </p>
          </dd>
          <dt>5. Intellectual Property</dt>
          <dd>
            <p>
We (and our licensors) own all proprietary and intellectual property rights in the Website
(including all information, data, text, graphics, artwork, photographs, logos, icons, sound
recordings, videos and look and feel), and the Underlying Systems.
            </p>
          </dd>
          <dt>6. Disclaimers</dt>
          <dd>
            <p>
6.1 To the extent permitted by law, we and our licensors have no liability or responsibility to you
or any other person for any Loss in connection with:
            </p>
            <ol type="a">
              <li>
the Website being unavailable (in whole or in part) or performing slowly;
              </li>
              <li>
any error in, or omission from, any information made available through the Website;
              </li>
              <li>
any exposure to viruses or other forms of interference which may damage your computer
system or expose you to fraud when you access or use the Website. To avoid doubt, you
are responsible for ensuring the process by which you access and use the Website
protects you from this; and
              </li>
              <li>
any site linked from the Website. Any link on the Website to other sites does not imply
any endorsement, approval or recommendation of, or responsibility for, those sites or their
contents, operations, products or operators.
              </li>
            </ol>
            <p>
6.2. We make no representation or warranty that the Website is appropriate or available for use
in all countries or that the content satisfies the laws of all countries. You are responsible
for ensuring that your access to and use of the Website is not illegal or prohibited, and for
your own compliance with applicable local laws.
            </p>
          </dd>
          <dt>7. Liability</dt>
          <dd>
            <p>7.1. To the maximum extent permitted by law:</p>
            <ol type="a">
              <li>you access and use the Website at your own risk; and</li>
              <li>
we are not liable or responsible to you or any other person for any Loss under or in
connection with these Terms, the Website, or your access and use of (or inability to
access or use) the Website. This exclusion applies regardless of whether our liability or
responsibility arises in contract, tort (including negligence), equity, breach of statutory
duty, or otherwise.
              </li>
            </ol>
            <p>
7.2. Except to the extent permitted by law, nothing in these Terms has the effect of contracting
out of the New Zealand Consumer Guarantees Act 1993 or any other consumer protection law that
cannot be excluded. To the extent our liability cannot be excluded but can be limited, our
liability is limited to NZD100.
            </p>
            <p>
7.3. To the maximum extent permitted by law and only to the extent clauses 7.1 and 7.2 of these
Terms do not apply, our total liability to you under or in connection with these Terms, or in
connection with the Website, or your access and use of (or inability to access or use) the
Website, must not exceed NZD100.
            </p>
          </dd>
          <dt>8. Privacy Policy</dt>
          <dd>
            <p>
8.1. You are not required to provide personal information to us, although in some cases if you
choose not to do so then we will be unable to make certain sections of the Website available to
you. For example, we may need to have your contact information in order to provide you with
updates from our Website.
            </p>
            <p>
8.2. When you provide personal information to us, we will comply with the New Zealand Privacy Act
1993 as detailed in our <a href="/privacy">privacy policy</a>.
            </p>
          </dd>
          <dt>9. Suspension And Termination</dt>
          <dd>
            <p>
9.1. Without prejudice to any other right or remedy available to us, if we consider that you have
breached these Terms or we otherwise consider it appropriate, we may immediately, and without
notice, suspend or terminate your access to the Website (or any part of it).
            </p>
            <p>
9.2. On suspension or termination, you must immediately cease using the Website and must not
attempt to gain further access.
            </p>
          </dd>
          <dt>10. General</dt>
          <dd>
            <p>
10.1. If we need to contact you, we may do so by email or by posting a notice on the Website. You
agree that this satisfies all legal requirements in relation to written communications.
            </p>
            <p>
10.2. These Terms, and any dispute relating to these Terms or the Website, are governed by and
must be interpreted in accordance with the laws of New Zealand. Each party submits to the
non-exclusive jurisdiction of the Courts of New Zealand in relation to any dispute connected with
these Terms or the Website.
            </p>
            <p>10.3. For us to waive a right under these Terms, the waiver must be in writing.</p>
            <p>
10.4. Clauses which, by their nature, are intended to survive termination of these Terms, including
clauses 4.5, 5, 6, 7, 10.1, continue in force.
            </p>
            <p>
10.5. If any part or provision of these Terms is or becomes illegal, unenforceable, or invalid,
that part or provision is deemed to be modified to the extent required to remedy the illegality,
unenforceability or invalidity. If a modification is not possible, the part or provision must be
treated for all purposes as severed from these Terms. The remainder of these Terms will be
binding on you.
            </p>
            <p>
10.6. These Terms set out everything agreed by the parties relating to your use of the Website and
supersede and cancel anything discussed, exchanged or agreed prior to you agreeing to these
Terms. The parties have not relied on any representation, warranty or agreement relating to the
Website that is not expressly set out in the Terms, and no such representation, warranty or
agreement has any effect from the date you agreed to these Terms.
            </p>
          </dd>
        </dl>
        <aside class="uk-placeholder uk-text-muted">
          <h4>Something amiss?</h4>
          <p class="uk-margin-large-left uk-text-right">
Is something here unclear, or otherwise concerning? We would love to hear about it
from you by email at
<a href="mailto:terms-of-use@squirreltechnologies.nz?Subject=About%20your%20terms%20%of20use..."
    target="_top">
    terms-of-use@squirreltechnologies.nz
</a>
          </p>
          <p class="uk-margin-large-left uk-text-right">
We are a young company, but we are committed to maintaining your privacy. We believe technology
should support and encourage both individuals and businesses, so we would appreciate being made
aware of any changes we can make to this document to further these aims.
          </p>
        </aside>
      </section>
      <SiteFooter class="uk-flex-none uk-flex-bottom"></SiteFooter>
    </article>
  </Lock>
</template>

<script>
import Lock from '@/components/Lock.vue';
import SiteHeader from '@/components/SiteHeader.vue';
import SiteFooter from '@/components/SiteFooter.vue';

export default {
  name: 'termsofuse',
  props: {},
  components: {
    Lock,
    SiteHeader,
    SiteFooter,
  },
};
</script>
